<template>
  <div>
    <!-- 中文 -->
    <div v-if="$store.state.show">
      <h2>SDK与Tools</h2>
      <p>
        本文档SDK不做特殊说明均指TiSDK，TiSDK是一款能够在您的项目中提供美颜、美型、贴纸、滤镜、美妆等特效的强大渲染
        引擎SDK。
      </p>
      <p>
        本文档Tools不做特殊说明均指贴纸配置工具Tools，用于配合您的设计师团队制作属于自己的贴纸道具，这些制作完成的贴
        纸配置文件结合贴纸资源，与SDK进行交互，即可在项目中展现自制贴纸特效。
      </p>
    </div>
    <!-- 英文 -->
    <div v-else>
      <h2>SDK and Tools</h2>
      <p>
        Typically without special instructions, this document SDK refers to the
        TiSDK, which is a powerful rendering engine SDK that can provide beauty,
        stickers, filters and other special effects in your project.
      </p>
      <p>
        Tools in this document refer to the Sticker Configuration Tools, which
        are used to make your own sticker props with your designer team. These
        finished Sticker Configuration files, combined with Sticker Resources,
        interact with the SDK to show the special effects of homemade stickers
        in your project.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props:['lang'],
  data() {
    return {
      show: true,
    };
  },
  created() {
  },
};
</script>
<style lang="scss" scoped>
h2 {
  font-size: 20px;
  font-weight: 500;
  color: #3c4858;
}
p {
  width: 763px;
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  color: #3c4858;
  margin: 20px 0;
}
</style>